* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgba(4, 4, 4, 0.4);

}

.todo-app {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}