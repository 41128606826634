div {
    display: flex;
    flex-direction: column;

}

h1 {
    font-size: 6rem;
    font-weight: 400;
    color: rgb(61, 61, 182);
}